import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'

import filreset from '../../assets/images/lock_reset (1).png'
import { redirect, useNavigate } from 'react-router-dom'
import rectangle1 from '../../assets/images/image 56.png'
import rectangle2 from '../../assets/images/image 57.png'
import rectangle3 from '../../assets/images/image 58.png'
import rectangle4 from '../../assets/images/image 59.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';
import DatePicker from 'react-datepicker';
import BookingForm from '../Home/BookingForm'
import GolfCourseList from '../GolfCourseList'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_COURSE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import moment from 'moment';
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
const GolfHolidaysList = () => {

    const dispatch = useDispatch();

    const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
    const [currencyRateList , setCurrencyRateList] = useState([]);
    const [currencyCode , setCurrencyCode] = useState('')
   
    const { availableCourses,bookingData }  = useSelector(state => state.booking)
    const [golfCourses, setGolfCourses] = useState([]);
    const [searchdata, setSearchdata] = useState("");
    const [minimumPrice, setMinimumPrice] = useState(0);
    const [maximumPrice, setMaximumPrice] = useState(0);
    const [filteredminimumPrice, setFilMinimumPrice] = useState(0);
    const [filteredmaximumPrice, setFilMaximumPrice] = useState(0);
    const [countries, setCountries] = useState([]);
    const [upcommingdates, setUpcommingDates] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [tabdetails, setTabDetails] = useState([]);
    // const [tempTabData, setTempTabData] = useState([]);
    const [gridCourses, setGridCourses] = useState([]);
    const navigation = useNavigate();
    const [golfContents, setGolfcontents] = useState([]);

    useEffect(() => {
      if(userCurrencyCode){
          setCurrencyCode(userCurrencyCode)
  }
    }, [userCurrencyCode]);

    useEffect(() => {
      if(currencyRate){
        setCurrencyRateList(currencyRate)
  }
    }, [currencyRate]);
    
    useEffect(() => {

       setSearchdata(localStorage.getItem("search"));
        if(availableCourses !==undefined){
            setGolfCourses(availableCourses)

            // let maxPrice = Math.max(...availableCourses.map(item => item.rate_sheets.start_from_cost))
            
            // setMaximumPrice(maxPrice+10)
            // setFilMaximumPrice(maxPrice)

            const allPrices = []
    
            availableCourses.forEach((course) => {
                const currencyPerPrice = currencyRateList[course?.rate_sheets?.currency?.currencyCode];
                const convertedPrice =  course.rate_sheets.start_from_cost / currencyPerPrice
                allPrices.push(convertedPrice > 0 ? convertedPrice : 0)
            });
      
            if (allPrices.length > 0) {
              const highestPrice = Math.max(...allPrices);
              const roundedPrice = Math.ceil(highestPrice + 10);
              setMaximumPrice(roundedPrice);
              setFilMaximumPrice(roundedPrice);
            }
            
            getUpcommingDays(); 
            fetchByTabCourses(); 
        }
       
      }, [availableCourses , currencyRateList])
      useEffect(() => {
        GetGolfContent(); 
      }, [])
      
      const GetGolfContent = () => {
      
          const payload = ["golf-holidays","golf-courses"];
          axios(API_URL+'/get-single-page',{
              method: 'POST',
              data: {slug:payload},
              headers: {
            'Content-Type': 'application/json',
             'Access-Control-Allow-Origin':'*'
             }
            })
              .then(function(response) {
                  // return response.json()
                  setGolfcontents(response.data);
                //   alert(golfContents[0].body);
                }).then(function(body) {
                });
      }

      const fetchByTabCourses=()=>{
        if(availableCourses !==undefined){
            var course_tab=[];
            var tabdata=[{
                tab:"All",
                courses:availableCourses
            }];
            // alert(tabdata[0]?.courses)
            filterCountries(tabdata[0]?.courses); 
            availableCourses.map(course=>{
                course_tab.push(course?.golf_courses?.course_type_name);
            });
            if(course_tab) {
                course_tab=course_tab.filter((value,index)=>course_tab.indexOf(value) ===index);

                course_tab?.map(tabs=>{
                    var arr=  getTabCoures(tabs)
                    arr.map((val)=>{
                        tabdata.push({
                            tab: tabs,
                            courses:arr[0]
                        })
                        })
                         
                })
                // console.log("tabdata",tabdata)
                setMainData(tabdata)
                setTabDetails(tabdata)
                
                // setTempTabData(tabdata)
                // setGridCourses(tabdata)
            }
             
        }
      }

      const handleTabChange=(list)=>{
        setActiveTab(list?.tab)
        filterCountries(list?.courses)
      
        var getActiveTabCoures=tabdetails?.filter(courses=>courses?.tab === list?.tab);

        // let maxPrice = Math.max(...getActiveTabCoures[0]?.courses?.map(item => item.rate_sheets.start_from_cost))
        // setMaximumPrice(maxPrice+10)
        // setFilMaximumPrice(maxPrice)

        const allPrices = []
    
        getActiveTabCoures[0]?.courses.forEach((course) => {
            const currencyPerPrice = currencyRateList[course?.rate_sheets?.currency?.currencyCode];
            const convertedPrice =  course.rate_sheets.start_from_cost / currencyPerPrice
            allPrices.push(convertedPrice > 0 ? convertedPrice : 0)
        });
  
        if (allPrices.length > 0) {
          const highestPrice = Math.max(...allPrices);
          const roundedPrice = Math.ceil(highestPrice + 10);
          setMaximumPrice(roundedPrice);
          setFilMaximumPrice(roundedPrice);
        }
      }

      const getTabCoures=(tabs)=>{
        var data=[];
        var res = availableCourses?.filter(course =>
            course?.golf_courses?.course_type_name ===tabs
        )
        data.push(res)
        return data;
      }

// GET THE UPCOMMING 5DAYS
      const getUpcommingDays=()=>{
        var day = new Date();
        var nextDay = new Date(day);
        nextDay.setDate(day.getDate() + 4);
        var start = moment(day, "DD-MM-YY");
        var end = moment(nextDay, "DD-MM-YY");
        // alert(start) 
        var dates = [];
        while (start.isSameOrBefore(end)) {
            dates.push(start.format('YYYY-MM-DD'));
            start.add(1, 'days');
        } 
        setUpcommingDates(dates)
      }


    const filterCountries = (courses) =>{
        var lists=[];
                courses?.map((countries) =>{                
                    lists.push(countries?.golf_courses?.country_relation?.name);
                })          
            lists=lists.filter((value,index)=>lists.indexOf(value) ===index);             
            setCountries(lists)    
    }
    const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
      const currencyRate = currencyRateList[currency];
      const convertedPrice =  price / currencyRate
      return convertedPrice.toFixed(2);
  }
    


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const { user } = useSelector(state => state.auth);
    const [activeTab, setActiveTab] = useState('All');
    const [tabLists, setTabLists] = useState([]);
    const tabs = ['All', 'Featured','Top Pick'];
    
    // --------------
    const [isOn, setIsOn] = useState(true); // Set initial state to true

    const handleToggle = () => {
        setIsOn(prevState => !prevState);
    };
    

    const [currentSortOrder, setCurrentSortOrder] = useState('Sort');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const handleSortChange = (value) => {
       if(value==="asc"){
       var ortedData= golfCourses.sort((a,b) =>{
        return a.golf_courses.golf_course_name.localeCompare(b.golf_courses.golf_course_name)
        })
       }else{
        var ortedData= golfCourses.sort((a,b) =>{
            return b.golf_courses.golf_course_name.localeCompare(a.golf_courses.golf_course_name)
            })
       }
     setGolfCourses(ortedData);
      setIsDropdownOpen(false);
    };
  
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
    
    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };


      const [isFilterVisible, setIsFilterVisible] = useState(false);
    
      useEffect(() => {
        if (isFilterVisible) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, [isFilterVisible]);
    

      const [selectedLocation, setSelectedLocation] = useState('All');

  
      const handleLocationChange = (event) => {
        console.log("activeTab",activeTab);
        console.log("tabdetails",tabdetails);
        var getActiveTabCoures=mainData?.filter(courses=>courses?.tab === activeTab);
        var replaceCourse=mainData?.filter(courses=>courses?.tab != activeTab);   
        console.log("getActiveTabCoures",getActiveTabCoures);
        var value =event.target.value;
        if(value !="All"){
            const filteredData = getActiveTabCoures[0]?.courses?.filter(item=>
                 item.golf_courses.country_id.includes(value)        
            ); 
            var datanew={
                tab:activeTab,
                courses:filteredData
            }
            replaceCourse.push(datanew)
            console.log("newfilteredData",replaceCourse);
            setTabDetails(replaceCourse);
        }else{
            setTabDetails(mainData);
        }
          setSelectedLocation(value);
          
      };
      
      const handlemaxrange =(event)=>{
        const value = parseFloat(event.target.value);
        setFilMaximumPrice(value)     
       
        // const filteredResult = mainData.filter(items =>
        //     items.rate_sheets.start_from_cost >= filteredminimumPrice && 
        //     items.rate_sheets.start_from_cost <= value 
        // );
        var getActiveTabCoures=mainData?.filter(courses=>courses?.tab === activeTab);
        var replaceCourse=mainData?.filter(courses=>courses?.tab != activeTab); 
        if(activeTab !="All"){

          const filteredData = getActiveTabCoures[0]?.courses?.filter(items => {
          const currencyPerPrice = currencyRateList[items?.rate_sheets?.currency?.currencyCode];
          const singleConvertedPrice = items.rate_sheets.start_from_cost / currencyPerPrice;
          return singleConvertedPrice >= filteredminimumPrice && 
                   singleConvertedPrice <= value;
          });
          var datanew={
              tab:activeTab,
              courses:filteredData
          }
          replaceCourse.push(datanew)
            setTabDetails(replaceCourse);
        }else{
          
          const filteredData = getActiveTabCoures[0]?.courses?.filter(items => {
            const currencyPerPrice = currencyRateList[items?.rate_sheets?.currency?.currencyCode];
            const singleConvertedPrice = items.rate_sheets.start_from_cost / currencyPerPrice;
            return singleConvertedPrice >= filteredminimumPrice && 
                     singleConvertedPrice <= value;
            });
        var datanew={
          tab:activeTab,
          courses:filteredData
      }
      replaceCourse.push(datanew)
      setTabDetails(replaceCourse);
        }
          // setGolfCourses(filteredData);
    }

      const handleminrange =(event)=>{
        const value = parseFloat(event.target.value);
        setFilMinimumPrice(value)   
        // const filteredResult = availableCourses.filter(items =>
        //     items.rate_sheets.start_from_cost >= value && 
        //     items.rate_sheets.start_from_cost <= filteredmaximumPrice 
        //   );
        //   console.log(filteredResult)
        //   setGolfCourses(filteredResult);
        var getActiveTabCoures=mainData?.filter(courses=>courses?.tab === activeTab);
        var replaceCourse=mainData?.filter(courses=>courses?.tab != activeTab); 
        console.log("getActiveTabCoures",getActiveTabCoures[0]);
        if(activeTab !="All"){
          const filteredData = getActiveTabCoures[0]?.courses?.filter(items => {
            const currencyPerPrice = currencyRateList[items?.rate_sheets?.currency?.currencyCode];
            const singleConvertedPrice = items.rate_sheets.start_from_cost / currencyPerPrice;
            return singleConvertedPrice >= value && 
                     singleConvertedPrice <= filteredmaximumPrice;
            });
          var datanew={
              tab:activeTab,
              courses:filteredData
          }
          replaceCourse.push(datanew)
          console.log("newfilteredData",replaceCourse);
            setTabDetails(replaceCourse);
        }else{
          const filteredData = getActiveTabCoures[0]?.courses?.filter(items => {
            const currencyPerPrice = currencyRateList[items?.rate_sheets?.currency?.currencyCode];
            const singleConvertedPrice = items.rate_sheets.start_from_cost / currencyPerPrice;
            return singleConvertedPrice >= value && 
                     singleConvertedPrice <= filteredmaximumPrice;
            });
        var datanew={
          tab:activeTab,
          courses:filteredData
      }
      replaceCourse.push(datanew)
      setTabDetails(replaceCourse);
        }
    }

    
      
      const resetFilter=()=>{
        setTabDetails(mainData)
        setSelectedLocation("All")
        setActiveTab("All")
        setFilMaximumPrice(maximumPrice)
        setActiveBox(5);
      }
      const [activeBox, setActiveBox] = useState(5);
       const count =5; //for rating point
      
      const filterByRating = (index) => {
        var getActiveTabCoures=mainData?.filter(courses=>courses?.tab === activeTab);
        var replaceCourse=mainData?.filter(courses=>courses?.tab != activeTab); 
        console.log("getActiveTabCoures",getActiveTabCoures);
       var filteredData= getActiveTabCoures[0]?.courses?.filter(items =>
         items?.golf_courses?.gt_rating <= index
       );
         console.log(filteredData);
         var datanew={
            tab:activeTab,
            courses:filteredData
          }
      replaceCourse.push(datanew)
            setTabDetails(replaceCourse)    
            setActiveBox(index);
    };
    const [choosedCourse, setChoosedCourse] = useState({});
    
    const handleCourse = (event) => {
        var value =parseInt(event.target.value);
        
        var choosesCoure= golfCourses.filter(courses => courses.golf_courses.id === value)
        setChoosedCourse(choosesCoure[0])
    };

    const  selectCurrentCourseList=()=>{
        if(choosedCourse){
        dispatch({
            type: SELECTED_COURSE,
            payload: choosedCourse
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedCourse: choosedCourse,
                id: 'current'
            }
        })
        navigation("/CourseDetailsPage")
      }else{
        alert("Please Choose the course")
    }
    }


    //   FOR GRID VIEW TABLE LIST
    const getSlotTime=(date,course)=>{
        var start_Date=course?.rate_sheets?.start_date;
        var end_Date=course?.rate_sheets?.end_date;
        let bool1 = moment(date).isBetween(start_Date, end_Date);
        let PriceArray=[];
        let html="<li> </li>";
        if(bool1){  
        course?.rate_sheets?.price_information?.map(priceInfo=>{
          const convertedPrice = fnctionConvertingPriceWithCurrency(priceInfo?.user_price , course?.rate_sheets?.currency?.currencyCode)
            let html="<li>"+currencyCode +" "+ convertedPrice+"</li>"; 
            PriceArray.push(html);
        })
            return PriceArray.join("");
        }else{
            return  html;
       }
    }

    return (
 
        <div className='listitem'>
      
        <div className='listitems-sec'>
                <Grid container spacing={2} className='list-dis-jus'>
                <Grid item xs={2.8} className={`filter-content-box ${isFilterVisible ? 'active' : ''}`}>
        <div className="filter-header">
          <div className="fil-lft-cont">
            <div className="fil-round">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M27.4672 5.46647V8.39981C27.4672 9.46647 26.8005 10.7998 26.1338 11.4665L20.4005 16.5331C19.6005 17.1998 19.0672 18.5331 19.0672 19.5998V25.3331C19.0672 26.1331 18.5338 27.1998 17.8672 27.5998L16.0005 28.7998C14.2672 29.8665 11.8672 28.6665 11.8672 26.5331V19.4665C11.8672 18.5331 11.3338 17.3331 10.8005 16.6665L10.1738 16.0131C9.7605 15.5731 9.6805 14.9065 10.0138 14.3865L16.8405 3.42647C17.0805 3.0398 17.5072 2.7998 17.9738 2.7998H24.8005C26.2672 2.7998 27.4672 3.9998 27.4672 5.46647Z"
                    fill="#232323"
                  />
                  <path
                    d="M13.7999 4.83981L9.06654 12.4265C8.6132 13.1598 7.5732 13.2665 6.9732 12.6398L5.7332 11.3331C5.06654 10.6665 4.5332 9.46647 4.5332 8.66647V5.5998C4.5332 3.9998 5.7332 2.7998 7.19987 2.7998H12.6665C13.7065 2.7998 14.3465 3.94647 13.7999 4.83981Z"
                    fill="#232323"
                  />
                </svg>
              </span>
            </div>
            <p>Filters</p>
          </div>
          <div className="fil-rgt-cont">
            <div className="fil-reset">
              <button onClick={()=>resetFilter()}>
                <span><img src={filreset} alt="reset-btn" /></span>Reset all
              </button>
            </div>
          </div>
        </div>
        <div className="filter-boxes">
        <div className="filter-box location">
            <h4>Location</h4>
            <div className="radio-button-items">
              <div className="radio-item">
                <input
                  type="radio"
                  id="location-all"
                  name="location"
                  value="All"
                  checked={selectedLocation === 'All'}
                  onChange={handleLocationChange}
                />
                <label htmlFor="location-all">All</label>
              </div>
              {golfCourses
                ? countries.map((country, index) => (
                    <div className="radio-item" key={index}>
                      <input
                        type="radio"
                        id={`location-${index}`}
                        name="location"
                        value={country}
                        checked={selectedLocation === country}
                        onChange={handleLocationChange}
                      />
                      <label htmlFor={`location-${index}`}>{country}</label>
                    </div>
                  ))
                : ""}
            </div>
    </div>

          <div className="filter-box price-range">
            <h4>Price</h4>
            <div className="range-bar">
                  <div class="price-input">
                    <div class="field">
                      <span>Min</span>
                      <input type="number" class="input-min" value={filteredminimumPrice} />
                    </div>
                    <div class="separator">-</div>
                    <div class="field">
                      <span>Max</span>
                      <input type="number" class="input-max" value={filteredmaximumPrice} />
                    </div>
                  </div>

                </div>
            <div className="range-bar">
              <div className="slider">
                <div className="progress"></div>
              </div>
              <div className="range-input">
          
                <input type="range"  onChange={(e)=>handleminrange(e)}  className="range-min" min={minimumPrice} max={maximumPrice} /> 
                
                <input type="range" onChange={(e)=>handlemaxrange(e)} 
                 className="range-max" min={minimumPrice} value={filteredmaximumPrice} max={maximumPrice}/>
                 
              </div>
            </div>
          </div>
          
          <div className="filter-box heading">
            <h4>Filter By Ratings</h4>
            <div className="num-recon">
            <div className="num-review-boxes">
                {[...Array(count)].map((_, index) => (
                    <div
                        key={index}
                        className={`review-count-box ${index + 1 <= activeBox ? 'rebtn-active' : ''}`}
                        onClick={() => filterByRating(index + 1)}
                    >
                        {index + 1}
                    </div>
                ))}
            </div>
          </div>
          </div>
        </div>
      </Grid>
      <button onClick={toggleFilterVisibility} className="filter-toggle-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M20.5999 4.09961V6.29961C20.5999 7.09961 20.0999 8.09961 19.5999 8.59961L15.2999 12.3996C14.6999 12.8996 14.2999 13.8996 14.2999 14.6996V18.9996C14.2999 19.5996 13.8999 20.3996 13.3999 20.6996L11.9999 21.5996C10.6999 22.3996 8.89988 21.4996 8.89988 19.8996V14.5996C8.89988 13.8996 8.49989 12.9996 8.09989 12.4996L7.62989 12.0096C7.31989 11.6796 7.25989 11.1796 7.50989 10.7896L12.6299 2.56961C12.8099 2.27961 13.1299 2.09961 13.4799 2.09961H18.5999C19.6999 2.09961 20.5999 2.99961 20.5999 4.09961Z" fill="white" />
                            <path d="M10.3504 3.62961L6.80039 9.31961C6.46039 9.86961 5.68039 9.94961 5.23039 9.47961L4.30039 8.49961C3.80039 7.99961 3.40039 7.09961 3.40039 6.49961V4.19961C3.40039 2.99961 4.30039 2.09961 5.40039 2.09961H9.50039C10.2804 2.09961 10.7604 2.95961 10.3504 3.62961Z" fill="white" />
                        </svg>
      </button>
                    <Grid xs={8.8} className='list-item-content'>
                        <h4>Golf Courses </h4>
                        <p>{golfContents[0]?.body}
                        </p>
                        <div className='filter-tab-sec'>
                            <div className='filter-tab-list'>
                                <div className="tab-list-lft">
                                    <ul>
                                        {tabdetails.map((list,index) => (
                                            <li key={index} className={list?.tab === activeTab ? 'tab-active' : ''} onClick={() => handleTabChange(list)}
                                            >
                                                {list?.tab}
                                            </li>
                                        ))}
                                    </ul> 
                                    <div className="tab-list-rgt">
                                    <div className="unique-header-sort">
                                        <div class="down-up-icon"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 36 36" fill="none"><path d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM16.485 25.5C16.485 25.65 16.455 25.785 16.395 25.935C16.275 26.205 16.065 26.43 15.78 26.55C15.645 26.61 15.495 26.64 15.345 26.64C15.195 26.64 15.06 26.61 14.91 26.55C14.775 26.49 14.655 26.415 14.55 26.31L10.125 21.885C9.69 21.45 9.69 20.73 10.125 20.295C10.56 19.86 11.28 19.86 11.715 20.295L14.22 22.8V10.5C14.22 9.885 14.73 9.375 15.345 9.375C15.96 9.375 16.485 9.885 16.485 10.5V25.5ZM25.86 15.72C25.635 15.945 25.35 16.05 25.065 16.05C24.78 16.05 24.495 15.945 24.27 15.72L21.765 13.215V25.5C21.765 26.115 21.255 26.625 20.64 26.625C20.025 26.625 19.515 26.115 19.515 25.5V10.5C19.515 10.35 19.545 10.215 19.605 10.065C19.725 9.795 19.935 9.57 20.22 9.45C20.49 9.33 20.805 9.33 21.075 9.45C21.21 9.51 21.33 9.585 21.435 9.69L25.86 14.115C26.295 14.565 26.295 15.285 25.86 15.72Z" fill="#292D32"></path></svg></div>
                                            <div className="unique-sort-name" onClick={toggleDropdown}>
                                                <li value={currentSortOrder}>{currentSortOrder}</li>
                                                <div className="unique-down-icon-top">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z" fill="#292D32" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            {isDropdownOpen && (
                                                <ul className="unique-dropdown-menu">
                                                    <li value="Sort" onClick={() => handleSortChange('Sort')}>Sort</li>
                                                    <li value="atoz" onClick={() => handleSortChange('asc')}>A to Z</li>
                                                    <li value="ztoa" onClick={() => handleSortChange('dsc')}>Z to A</li>
                                                </ul>
                                            )}
                                        </div>
                                        <div className="list-show-toggle-btn">
                                            <button
                                                onClick={handleToggle}
                                                className={isOn ? 'toggle-btn on' : 'toggle-btn off'}
                                            >
                                                {isOn ? (
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" fill="#232323" />
                                                            <path d="M18.6699 2H16.7699C14.5899 2 13.4399 3.15 13.4399 5.33V7.23C13.4399 9.41 14.5899 10.56 16.7699 10.56H18.6699C20.8499 10.56 21.9999 9.41 21.9999 7.23V5.33C21.9999 3.15 20.8499 2 18.6699 2Z" fill="#232323" />
                                                            <path d="M18.6699 13.4299H16.7699C14.5899 13.4299 13.4399 14.5799 13.4399 16.7599V18.6599C13.4399 20.8399 14.5899 21.9899 16.7699 21.9899H18.6699C20.8499 21.9899 21.9999 20.8399 21.9999 18.6599V16.7599C21.9999 14.5799 20.8499 13.4299 18.6699 13.4299Z" fill="#232323" />
                                                            <path d="M7.24 13.4299H5.34C3.15 13.4299 2 14.5799 2 16.7599V18.6599C2 20.8499 3.15 21.9999 5.33 21.9999H7.23C9.41 21.9999 10.56 20.8499 10.56 18.6699V16.7699C10.57 14.5799 9.42 13.4299 7.24 13.4299Z" fill="#232323" />
                                                        </svg>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M20 7H4C2.9 7 2 6.1 2 5V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V5C22 6.1 21.1 7 20 7ZM20 9.5H4C2.9 9.5 2 10.4 2 11.5V12.5C2 13.6 2.9 14.5 4 14.5H20C21.1 14.5 22 13.6 22 12.5V11.5C22 10.4 21.1 9.5 20 9.5ZM20 17H4C2.9 17 2 17.9 2 19V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V19C22 17.9 21.1 17 20 17Z" fill="white" />
                                                        </svg>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                   
                                   {tabdetails?.map((list,index) => (
                                    activeTab === list?.tab && 
                                    <div className="search-result-list">
                                    <div className="golf-find-total"> 
                                        <h4>{list ? list?.courses?.length:''} found {list?.tab}</h4>
                                        <button onClick={selectCurrentCourseList} className={isOn ? 'list-table-sec off' : 'list-table-sec on'}>Next <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                            <path d="M8.35001 15.5666L11.025 12.8916L12.6667 11.2583C13.3583 10.5666 13.3583 9.44163 12.6667 8.74997L8.35001 4.4333C7.78334 3.86663 6.81668 4.27497 6.81668 5.06663L6.81668 9.74163L6.81668 14.9333C6.81668 15.7333 7.78334 16.1333 8.35001 15.5666Z" fill="white" />
                                        </svg></span></button>
                                    </div>
                                    <Grid container spacing={2} className={isOn ? 'list-grid-no-mar on' : 'list-grid-no-mar off'}>
                                 
                                    { list?.courses ?
                                    list?.courses?.map(course => ( 
                                        <GolfCourseList 
                                        courses={course}
                                    />  
                                          ))
                                          : <div style={{display:'flex',justifyContent:'center'}} >
                                        <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                                            No Courses Found !
                                        </Typography>
                                     </div>}
                                    </Grid>
                                    <div className={isOn ? 'list-table-sec off' : 'list-table-sec on'}>
                                       {/* rubesh grid view table here */}
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" className='ta-bg-back'>
                                                        </TableCell>
                                                        <TableCell align="center" className='ta-bg-back'>
                                                            <div className='ta-head-dis'>
                                                                <div className='table-icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 20" fill="none">
                                                                        <path d="M15.4999 18.9998C15.0832 18.9998 14.729 18.8539 14.4374 18.5623C14.1457 18.2706 13.9999 17.9164 13.9999 17.4998C13.9999 17.0831 14.1457 16.7289 14.4374 16.4373C14.729 16.1456 15.0832 15.9998 15.4999 15.9998C15.9165 15.9998 16.2707 16.1456 16.5624 16.4373C16.854 16.7289 16.9999 17.0831 16.9999 17.4998C16.9999 17.9164 16.854 18.2706 16.5624 18.5623C16.2707 18.8539 15.9165 18.9998 15.4999 18.9998ZM5.99988 19.9998C4.33321 19.9998 2.91654 19.8039 1.74988 19.4123C0.583211 19.0206 -0.00012207 18.5498 -0.00012207 17.9998C-0.00012207 17.6164 0.274878 17.2748 0.824878 16.9748C1.37488 16.6748 2.09988 16.4331 2.99988 16.2498V16.9998C2.99988 17.2831 3.09571 17.5206 3.28738 17.7123C3.47904 17.9039 3.71654 17.9998 3.99988 17.9998C4.28321 17.9998 4.52071 17.9039 4.71238 17.7123C4.90404 17.5206 4.99988 17.2831 4.99988 16.9998V1.59978C4.99988 1.21644 5.15821 0.928942 5.47488 0.737275C5.79154 0.545608 6.11654 0.533109 6.44988 0.699775L11.1999 3.02478C11.5832 3.20811 11.7749 3.50811 11.7749 3.92478C11.7749 4.34144 11.5915 4.64144 11.2249 4.82478L6.99988 6.99978V16.0498C8.43321 16.1331 9.62488 16.3539 10.5749 16.7123C11.5249 17.0706 11.9999 17.4998 11.9999 17.9998C11.9999 18.5498 11.4165 19.0206 10.2499 19.4123C9.08321 19.8039 7.66654 19.9998 5.99988 19.9998Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                                <div className='table-head-tit'>
                                                                    <p>Golf Course</p>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center" className='ta-bg-back'>
                                                            <div className='ta-head-dis'>
                                                                <div className='table-icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M12 4.6499C7.21996 4.6499 3.32996 8.5399 3.32996 13.3199C3.32996 18.0999 7.21996 21.9999 12 21.9999C16.78 21.9999 20.67 18.1099 20.67 13.3299C20.67 8.5499 16.78 4.6499 12 4.6499ZM12.75 12.9999C12.75 13.4099 12.41 13.7499 12 13.7499C11.59 13.7499 11.25 13.4099 11.25 12.9999V7.9999C11.25 7.5899 11.59 7.2499 12 7.2499C12.41 7.2499 12.75 7.5899 12.75 7.9999V12.9999Z" fill="white" />
                                                                        <path d="M14.89 3.45H9.11001C8.71001 3.45 8.39001 3.13 8.39001 2.73C8.39001 2.33 8.71001 2 9.11001 2H14.89C15.29 2 15.61 2.32 15.61 2.72C15.61 3.12 15.29 3.45 14.89 3.45Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                                <div className='table-head-tit'>
                                                                    <p>Tee Time</p>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    {upcommingdates?.map(datelabel=>
                                                        <TableCell align="center" className='ta-bg-brown'>
                                                        <p>{datelabel}</p>
                                                    </TableCell>
                                                    )}
                                                        
                                                    </TableRow>
                                                </TableHead>
                                               
                                                <TableBody>
                                                { list?.courses ?
                                                    list?.courses?.map(course => ( 
                                                    <TableRow className='ta-details-box'>
                                                        <TableCell align='center' scope="row">
                                                            <div className="checkbox-wrapper-13">
                                                                <input                                           
                                                                type="radio"
                                                                value={course?.golf_courses?.id}
                                                            
                                                                onChange={handleCourse}
                                                                name="selectCourse" />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            <div>
                                                                <h5>{course?.golf_courses?.golf_course_name}</h5>
                                                                <ul>
                                                                    <li>Green Fee Only <span>({course?.golf_courses?.number_of_holes_filter} Holes)</span></li>
                                                                    {/* <li>Green Fee Only <span>(18 Holes)</span></li> */}
                                                                </ul>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <div>
                                                                <ul>
                                                                  
                                                                    {course?.rate_sheets?.price_information?.map(tea_time=>(
                                                                        <li>{tea_time.start_time} - {tea_time.end_time}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </TableCell>
                                                        {upcommingdates?.map(datelabel=>
                                                        <TableCell align='center'>
                                                            <div>
                                                                <ul>
                                                                   
                                                                    <div dangerouslySetInnerHTML={{ __html: getSlotTime(datelabel,course) }} />
                                                                </ul>
                                                            </div>
                                                        </TableCell>
                                                        )}
                                                      
                                                    </TableRow>
                                                    )): <div style={{display:'flex',justifyContent:'center'}} >
                                                  <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                                                      No Courses Found !
                                                  </Typography>
                                               </div>}
                                                    
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                   ))}
                                   
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
           </div>
        </div>
    )
}

export default GolfHolidaysList;
