import React, { useEffect } from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box, Button } from '@mui/material'
import './style.scss'
import HomeTopBar from '../../components/HomeTopBar'
import Footer from '../../components/Footer'
import instantbg from '../../assets/images/-d95e-4c23-8316-ae78f374160d (1).mp4'
import BookingForm from './BookingForm'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {getGolfInstantPackageList } from '../../Redux/actions/bookingActions'
import PackageList from './PackageList'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_PACKAGES, SET_SELECTED_PACKAGE } from '../../Redux/constants/bookingConstants'
const Index = () => {
    const dispatch = useDispatch();
    const { golfInstantPackages }  = useSelector(state => state.booking)
    const [packages, setPackages] = useState(golfInstantPackages);
    const [searchinput, setSearchInput] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [golfers, setGolfers] = useState(1);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [isContinueClicked, setIsContinueClicked] = useState(false);
    const [daysArray, setDaysArray] = useState([]);
    
    useEffect(() => {
       
        
         if(golfInstantPackages !==undefined){
            setPackages(golfInstantPackages)
             
         }
        
       }, [golfInstantPackages])
    const handleSearch = (e) => {
        var start = moment(startDate, "YYYY-MM-DD");
        var end = moment(endDate, "YYYY-MM-DD");
       
        // var days =moment.duration(end.diff(start)).asDays();
        e.preventDefault();
       
        var dates = [];
        while (start.isSameOrBefore(end)) {
            dates.push(start.format('DD-MM-YYYY'));
            start.add(1, 'days');
        }
       
        if(dates.length >10){
            alert("You Cannot choose more than 5 days");
        }else{
           
            var datesformat=[];
            dates?.map((day,index)=>{
                var data={
                    id:index+1,
                    date:day,
                    isGolfed:true
                }
                datesformat.push(data)
            })
         
            setDaysArray(datesformat);
            setIsSearchClicked(true); // Show the .full-con-width div
        }
         
    };

    const handleContinue = async() => {
        setIsSearchClicked(false);
        setIsContinueClicked(true);
        var payload={
            search:searchinput,
            start_date: moment(startDate, "YYYY-MM-DD").format('YYYY-MM-DD'),
            end_date: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        }
        await dispatch(getGolfInstantPackageList(payload,daysArray));
    };

    

    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    
    const { user } = useSelector(state => state.auth);

    const handleGolfChange = (e,id)=>{
        var value =e.target.value;
        if(value==="on"){
            var data = daysArray.map((item, idx) =>
                item.id === id ? { ...item, isGolfed: true } : item
              )
              setDaysArray(data);
        }else{
            var data = daysArray.map((item, idx) =>
                item.id === id ? { ...item, isGolfed: false } : item
              )
              setDaysArray(data);
        }
    }
    return (
        <div className='full-width-home instant-homepage'>
            <div className="mainHeader">
                <div className="header-width-pad">
                    <HomeTopBar activeMenu='1' />
                </div>
            </div>
            <div className="instantbg-home">
                <video autoPlay loop muted playsInline className="background-video">
                    <source src={instantbg} type='video/mp4' />
                </video>
                <h2>Instant Packages</h2>
                <p>Instant golf vacation travel package deal by choosing.</p>
                <div className='instantpack-form'>
                    <form>
                        <div className="search-box homesearch">
                            <div className="search-option">
                                <label className='search-lab' htmlFor="search">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <path d="M12.0009 13.4295C13.724 13.4295 15.1209 12.0326 15.1209 10.3095C15.1209 8.58633 13.724 7.18945 12.0009 7.18945C10.2777 7.18945 8.88086 8.58633 8.88086 10.3095C8.88086 12.0326 10.2777 13.4295 12.0009 13.4295Z" stroke="#292D32" strokeWidth="1.5" />
                                            <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#292D32" strokeWidth="1.5" />
                                        </svg>
                                    </span>
                                </label>
                                <input type="text" value={searchinput} name="search" onChange={(e)=>setSearchInput(e.target.value)} placeholder='Search' />
                            </div>
                            <div className='searchbx-comdiv'>
                                <div className="searchbx-date">
                                    <div className="date-pick">
                                        <label htmlFor="date-picker" id='date-picker'>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M3.5 9.08984H20.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.6937 13.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.6937 16.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9945 13.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9945 16.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.29529 13.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.29529 16.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd - MM - yyyy"
                                            placeholderText="From Date"
                                            isClearable
                                            className="date-picker-input"
                                            minDate={new Date()} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='searchbx-comdiv'>
    <div className="searchbx-date">
        <div className="date-pick">
            <label htmlFor="date-picker" id='date-picker'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M8 2V5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 2V5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.5 9.08984H20.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.6937 13.6992H15.7027" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.6937 16.6992H15.7027" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9945 13.6992H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9945 16.6992H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.29529 13.6992H8.30427" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.29529 16.6992H8.30427" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </label>
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd - MM - yyyy"
                placeholderText="To Date"
                isClearable
                className="date-picker-input"
                minDate={new Date(startDate)} 
            />
        </div>
    </div>
</div>

                            <div className='searchbx-comdiv'>
                                <div className="searchbx-golfers">
                                    <label htmlFor="date-picker" id='date-picker'>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.5 9.08984H20.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.6937 13.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.6937 16.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.9945 13.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.9945 16.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.29529 13.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.29529 16.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    </label>
                                    <select value={golfers} onChange={(e) => setGolfers(e.target.value)} className="golfers-dropdown">
                                        {[...Array(10).keys()].map(num => (
                                            <option key={num + 1} value={num + 1}>{num + 1} Golfer{num + 1 > 1 ? 's' : ''}</option>
                                        ))}
                                    </select>
                                    <span className="dropdown-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 10l5 5 5-5H7z" fill="#292D32" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="search-btn">
                                <button type="submit" onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {isSearchClicked && !isContinueClicked && (
                
                <div className='full-con-width padd-full'>
                  
                    <Grid container spacing={1} className='instant-boxes'>
                      
                       { daysArray?.map((details,index) =>(
                       
                        <Grid xs={1.5} key={index} className="instant-box">
                            
                        <div className='ins-bx-head'>
                            <h4>Day {details?.id}</h4>
                            <p>{details?.date}</p>
                        </div>
                        <div className='ins-bx-check'>
                            <div class="checkbox-wrapper-13 instant-check-df">
                                <input type="radio" value="on" checked={details?.isGolfed} onChange={(e)=>handleGolfChange(e,details?.id)} key={details?.id} name={"isGolf" + details?.id}/>
                                <label for="c1-13">Golf</label></div>
                            <div class="checkbox-wrapper-13 instant-check-df">
                                <input type="radio" value="off" onChange={(e)=>handleGolfChange(e,details?.id)} key={details?.id} name={"isGolf" + details?.id} />
                                <label for="c1-13">No Golf</label></div>
                        </div>
                    </Grid>
                        ))}
                       
                      
                    </Grid>
                    <div className='ins-con-btn'>
                        <button onClick={handleContinue}>Continue</button>
                    </div>
                </div>
            )}
 
            {isContinueClicked && (
                <div className="full-con-width instant-list-width">
                    <p className='ins-con-listcount'>{golfInstantPackages?.length || 0} Packages found</p>
                    <div>
                      {golfInstantPackages?.map((inspackage)=>(
                         <PackageList list={inspackage} />
                      ))}
                       
                    </div>
                </div>
            )}
            <Footer />
        </div>
    )
}

export default Index;
