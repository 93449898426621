import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import './Cart.scss'

import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUsers, faChild, faBed } from "@fortawesome/free-solid-svg-icons";
import { courseBookingOrders } from '../../Redux/actions/bookingActions'
import { faAngleDown, faAngleUp ,faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import CartCard from './CartCard'
import { DataF } from '@react-google-maps/api'
import { CART_COUNT } from '../../Redux/constants/bookingConstants'
import { getFooterMenus ,getcartCount} from '../../Redux/actions/bookingActions';
const CartPageDetails = () => {
    const { isAuthenticated, previousRoute, user } = useSelector(state => state.auth)
    // ----date--------------setEndDate
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [cartData, setCartData] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
    const [currencyCode , setCurrencyCode] = useState('')
    const [currencyRateList , setCurrencyRateList] = useState([]);
  
        useEffect(() => {
        if(userCurrencyCode){
            setCurrencyCode(userCurrencyCode)
    }
        }, [userCurrencyCode]);
    
        useEffect(() => {
        if(currencyRate){
            setCurrencyRateList(currencyRate)
    }
        }, [currencyRate]);


    useEffect(() => {
        getCartData()
        calculateTotalCart()
    }, [])
    const getCartData=()=>{
        const CartData =  JSON.parse(localStorage.getItem("cartData"));
        const cartcount =  localStorage.getItem("cartData");
        if(CartData){
           
            setCartData(CartData)
        }
    }
    const calculateTotalCart=()=>{
        const CartData =  cartData;//JSON.parse(localStorage.getItem("cartData"));
        var total = 0;
        if(CartData){
            CartData.forEach(function (charge) {
                const totalPriceForCart = fnctionConvertingPriceWithCurrency(parseFloat(charge.selectedPrice) , charge.currency)
                total += totalPriceForCart * parseFloat(charge.selectedGolfers);
            });
        }
           
      
        return total.toFixed(2)
    }
    const calculatePricePerPerson=()=>{
        const CartData =  JSON.parse(localStorage.getItem("cartData"));
        var total = 0;
        if(CartData){
            CartData.forEach(function (charge) {
                total += parseFloat(fnctionConvertingPriceWithCurrency(charge.selectedPrice,charge.currency));
            });
        }
        return total.toFixed(2)
    }
   
 

    // -------------------golfercount-------------

    const [selectedGolfers, setSelectedGolfers] = useState(3);

    const handleChange = (event) => {
        setSelectedGolfers(event.target.value);
    };

    // --------------------------------

    const [bookingStartDate, setBookingStartDate] = useState(null);
    const [bookingEndDate, setBookingEndDate] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedChildren, setSelectedChildren] = useState(0);
    const [selectedAdults, setSelectedAdults] = useState(1);


    // -------------------------
  
   
    const toggleAccordion = (id) => {
       
        const updatedAccordions = cartData.map(acc => {
            if (parseInt(acc.course_id) === id) {
                return { ...acc, isOpen: !acc.isOpen };
            }
            return acc;
        });
    //    console.log(updatedAccordions)
   
        setCartData(updatedAccordions);
    };
    
    const removeItem = (id) => {
       
        var cartitems =JSON.parse(localStorage.getItem("cartData")) || [];
        cartitems = cartitems.filter((data)=> data?.course_id != id)    
        var cartcount=cartitems?.length || 0;
        localStorage.setItem("cartData",JSON.stringify(cartitems))
       
        dispatch(getcartCount(cartcount))
        getCartData()
      }

      
      const handleBooking=(name,value,id)=>{
      
       if(name==="golfers"){
        var data = cartData.map((item, idx) =>
            item.course_id === id ? { ...item, selectedGolfers: value } : item
          )
        setCartData(data);
        localStorage.setItem("cartData",JSON.stringify(data))
       
       }else if(name==="timestamp"){
        var numbers=name.replace(currencyCode, '');
        var price = numbers.substring(numbers.indexOf( "(" ) + 1, numbers.indexOf( ")" ) )
        var timeselected = numbers.substring(0, numbers.indexOf("(") - 0)
      
       }
        
      }
      const PassangerDetailsPage = () => {
        let selectedData = JSON.stringify(cartData);
        let courseData = JSON.parse(selectedData);
        if (!courseData || courseData.length === 0) {
            alert("Your Cart is empty");
            return;
        }
        for (let course of courseData) {
            if (course?.selectedGolfers < course?.pax) {
                alert("Please Select Minimum Golfers");
                return; 
            }
        }
    
        dispatch(courseBookingOrders(courseData));
    
        if (!isAuthenticated) {
            navigate('/signin');
        } else {
            navigate('/PassangerDetailsPage');
        }
    };
      const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
        const currencyRate = currencyRateList[currency];
        const convertedPrice =  price / currencyRate
        return convertedPrice.toFixed(2);
    }
     
    return (
        <div className='listitem'>
            <div className='cart-container'>
                <h4 className='cartpg-heading'>Your Cart</h4>
                <Grid container spacing={2} className='container-d-row'>
                    <Grid xs={9} className='cartsec-lft-cont'>
                        <div className='cart-lft-container'>
                            <div className='cart-golfcourseboxes'>
                            { cartData.length>0 ?
                                <div className='cart-subheading'>
                                    <h5>Golf Course</h5>
                                </div>:""}
                                
                                { cartData.length>0 ? 
                                    cartData?.map(itemsdetails => (  
                                <CartCard itemsdetails={ itemsdetails } removeProduct={removeItem} handlebookingdata={handleBooking}/>                
                                  ))
                                 : <div style={{display:'flex',justifyContent:'center'}} >
                                    <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                                         <div className='search-btn cart-empty-btn'>
                                            Your Cart Is Empty! 
                            <button onClick={()=>navigate("/SearchResult")}><FontAwesomeIcon icon={faShoppingCart} /> Shop Now </button>
                        </div>
                                    </Typography>
                                </div>}  
                               
                            </div>
                            {/* <div className='cart-lodgingboxes'>
                                <div className='cart-subheading'>
                                    <h5>Lodging</h5>
                                </div>
                                <div className='cart-lodgingbox'>
                                    <Grid container spacing={2} className='container-d-row'>
                                        <Grid xs={10} className='cart-lodginsecbx'>
                                            <div className='cartbx-firstone'>
                                                <div className='cartbx-hotelimg'>
                                                    <img src={carthotel} alt="image" />
                                                </div>
                                                <div className='cartlodgbx-details'>
                                                    <div className='cartsubdetails'>
                                                        <div>
                                                            <h5>Duangtawan Hotel Chiang Mai</h5>
                                                            <p><span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
                                                                <path d="M13.7467 5.63464C13.0467 2.55464 10.3601 1.16797 8.00006 1.16797C8.00006 1.16797 8.00006 1.16797 7.9934 1.16797C5.64006 1.16797 2.94673 2.54797 2.24673 5.62797C1.46673 9.06797 3.5734 11.9813 5.48006 13.8146C6.18673 14.4946 7.0934 14.8346 8.00006 14.8346C8.90673 14.8346 9.8134 14.4946 10.5134 13.8146C12.4201 11.9813 14.5267 9.07463 13.7467 5.63464ZM8.00006 8.97463C6.84006 8.97463 5.90006 8.03464 5.90006 6.87464C5.90006 5.71464 6.84006 4.77464 8.00006 4.77464C9.16006 4.77464 10.1001 5.71464 10.1001 6.87464C10.1001 8.03464 9.16006 8.97463 8.00006 8.97463Z" fill="#292D32" />
                                                            </svg></span>132 Loi Kroh Road | Chiang Mai, Thailand</p>
                                                        </div>
                                                    </div>
                                                    <div className='cartbx-secondone'>
                                                        <Grid container spacing={2} className='container-d-row selected-in-box'>
                                                            <Grid xs={3.9}>
                                                                <div className="side-datepick">
                                                                    <p className="side-in-tit">Date Range</p>
                                                                    <div className="in-fill">
                                                                        <label>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                                            </span>
                                                                        </label>
                                                                        <DatePicker
                                                                            selected={bookingStartDate}
                                                                            onChange={(update) => {
                                                                                setBookingStartDate(update[0]);
                                                                                setBookingEndDate(update[1]);
                                                                            }}
                                                                            startDate={bookingStartDate}
                                                                            endDate={bookingEndDate}
                                                                            selectsRange
                                                                            dateFormat="yyyy/MM/dd"
                                                                            placeholderText="Select date range"
                                                                            className="date-picker-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid xs={3.9}>
                                                                <div className="side-in">
                                                                    <p className="side-in-tit">Room</p>
                                                                    <div className="in-fill">
                                                                        <label>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faBed} />
                                                                            </span>
                                                                        </label>
                                                                        <select value={selectedRoom} onChange={(e) => setSelectedRoom(e.target.value)}>
                                                                            <option value="" disabled>Select Room</option>
                                                                            <option value="1">Room 1</option>
                                                                            <option value="2">Room 2</option>
                                                                            <option value="3">Room 3</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid xs={1.9}>
                                                                <div className="side-in">
                                                                    <p className="side-in-tit">Children</p>
                                                                    <div className="in-fill">
                                                                        <label>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faChild} />
                                                                            </span>
                                                                        </label>
                                                                        <select value={selectedChildren} onChange={(e) => setSelectedChildren(e.target.value)}>
                                                                            <option value="0">0</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid xs={1.9}>
                                                                <div className="side-in">
                                                                    <p className="side-in-tit">Adults</p>
                                                                    <div className="in-fill">
                                                                        <label>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faUsers} />
                                                                            </span>
                                                                        </label>
                                                                        <select value={selectedAdults} onChange={(e) => setSelectedAdults(e.target.value)}>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>

                                                    <div className='cartbx-secondone'>
                                                        <Grid container spacing={2} className='container-d-row selected-in-box'>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={4}>
                                                                    <div className="side-datepick">
                                                                        <p className="side-in-tit">Date Range</p>
                                                                        <div className="in-fill">
                                                                            <label>
                                                                                <span>
                                                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                                                </span>
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={startDate}
                                                                                onChange={(update) => {
                                                                                    setStartDate(update[0]);
                                                                                    setEndDate(update[1]);
                                                                                }}
                                                                                startDate={startDate}
                                                                                endDate={endDate}
                                                                                selectsRange
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select date range"
                                                                                className="date-picker-input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <div className="side-in">
                                                                        <p className="side-in-tit">Room</p>
                                                                        <div className="in-fill">
                                                                            <label>
                                                                                <span>
                                                                                    <FontAwesomeIcon icon={faBed} />
                                                                                </span>
                                                                            </label>
                                                                            <select value={selectedRoom} onChange={(e) => setSelectedRoom(e.target.value)}>
                                                                                <option value="" disabled>Select Room</option>
                                                                                <option value="1">Room 1</option>
                                                                                <option value="2">Room 2</option>
                                                                                <option value="3">Room 3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <div className="side-in">
                                                                        <p className="side-in-tit">Children</p>
                                                                        <div className="in-fill">
                                                                            <label>
                                                                                <span>
                                                                                    <FontAwesomeIcon icon={faChild} />
                                                                                </span>
                                                                            </label>
                                                                            <select value={selectedChildren} onChange={(e) => setSelectedChildren(e.target.value)}>
                                                                                <option value="0">0</option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <div className="side-in">
                                                                        <p className="side-in-tit">Adults</p>
                                                                        <div className="in-fill">
                                                                            <label>
                                                                                <span>
                                                                                    <FontAwesomeIcon icon={faUsers} />
                                                                                </span>
                                                                            </label>
                                                                            <select value={selectedAdults} onChange={(e) => setSelectedAdults(e.target.value)}>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                                <option value="4">4</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid xs={1.8} className='cart-totalsec-bx'>
                                            <h5>Total Price: <span>$120</span></h5>
                                            <div className='cart-btns'>
                                                <button className='cart-changebtn'><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                    <g clip-path="url(#clip0_347_2083)">
                                                        <path d="M10.0002 4.16536V0.832031L5.8335 4.9987L10.0002 9.16536V5.83203C12.7585 5.83203 15.0002 8.0737 15.0002 10.832C15.0002 13.5904 12.7585 15.832 10.0002 15.832C7.24183 15.832 5.00016 13.5904 5.00016 10.832H3.3335C3.3335 14.5154 6.31683 17.4987 10.0002 17.4987C13.6835 17.4987 16.6668 14.5154 16.6668 10.832C16.6668 7.1487 13.6835 4.16536 10.0002 4.16536Z" fill="#008D0E" />
                                                    </g>
                                                </svg></span>Change</button>
                                                <button className='cart-removebtn'><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                    <g clip-path="url(#clip0_347_2075)">
                                                        <path d="M4.99984 15.8333C4.99984 16.75 5.74984 17.5 6.6665 17.5H13.3332C14.2498 17.5 14.9998 16.75 14.9998 15.8333V7.5C14.9998 6.58333 14.2498 5.83333 13.3332 5.83333H6.6665C5.74984 5.83333 4.99984 6.58333 4.99984 7.5V15.8333ZM14.9998 3.33333H12.9165L12.3248 2.74167C12.1748 2.59167 11.9582 2.5 11.7415 2.5H8.25817C8.0415 2.5 7.82484 2.59167 7.67484 2.74167L7.08317 3.33333H4.99984C4.5415 3.33333 4.1665 3.70833 4.1665 4.16667C4.1665 4.625 4.5415 5 4.99984 5H14.9998C15.4582 5 15.8332 4.625 15.8332 4.16667C15.8332 3.70833 15.4582 3.33333 14.9998 3.33333Z" fill="#E40000" />
                                                    </g>
                                                </svg></span>Remove</button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div> */}
                        </div>
                    </Grid>
                    { cartData.length>0 ?
                    <>
                    <Grid xs={2.8} className='cart-sidesummarybx'>
                   
                        <h4>Summary</h4>
                        <div className='cart-sidesummary-golfcourse'>
                            <div className='cart-sidesubhead'>
                                <h5>Golf Courses</h5>
                            </div>
                            <div className="accordion-list">
                                 { cartData.length>0 ? 
                                    cartData?.map(itemsdetails => (  
                                        <div className="accordion-container" key={itemsdetails.course_id}>
                                        <div className="accord-heading" key={itemsdetails.course_id} onClick={() => toggleAccordion(itemsdetails.course_id)}>
                                            <p>{itemsdetails.course_name} <span><FontAwesomeIcon icon={itemsdetails.isOpen ? faAngleUp : faAngleDown} /></span></p>
                                            <h5>{currencyCode}  {fnctionConvertingPriceWithCurrency(itemsdetails.selectedPrice, itemsdetails.currency)}</h5>
                                        </div>
                                        {itemsdetails.isOpen && (
                                            <div className="accord-details">
                                                {itemsdetails.additional_services.map(services =>(
                                                     <div>
                                                     <p>{services.service_name}</p>                          
                                                 </div>
                                                ))}                           
                                            </div>
                                        )}
                                    </div>      
                                  ))
                                 : <div style={{display:'flex',justifyContent:'center'}} >
                                    <Typography fontFamily={'Nunito'} fontSize={14} fontWeight={'bold'} letterSpacing={1}>
                                       No Coures selected!
                                    </Typography>
                                </div>}  
                            </div>
                            <div className='cart-side-toper'>
                                <p>Total Price/Person</p>
                                <h5>{currencyCode}  {calculatePricePerPerson()}</h5>
                            </div>
                            <div className='cart-side-total'>
                                <p>Total Price</p>
                                <h5>{currencyCode}  {calculateTotalCart()}</h5>
                            </div>
                        </div> 
                        {/* <div className='cart-sidesummary-lodging'>
                            <div className='cart-sidesubhead'>
                                <h5>Lodging</h5>
                            </div>
                            <div className='cart-side-toper'>
                                <p>Duangtawan Hotel Chiang Mai</p>
                                <h5>$190</h5>
                            </div>
                            <div className='cart-side-toper'>
                                <p>Price/Person</p>
                                <h5>$190</h5>
                            </div>
                        </div>
                        <div className='cart-sidesummary-finaltotal'>
                            <div className='cart-finalper'>
                                 <p>Price/ Person</p>
                                 <h5>$166</h5>
                            </div>
                            <div className='cart-grandtotal'>
                                 <p>Grand Total</p>
                                 <h5>$166</h5>
                            </div>
                        </div> */}
                        <div className='enq-btn'>
                            <button onClick={PassangerDetailsPage}>Enquire Now</button>
                        </div>
                       
                       
                    </Grid>
                    </> :""}
                </Grid>
            </div>
        </div>
    )
}

export default CartPageDetails;
