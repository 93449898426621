import { Divider, Grid, Typography } from '@mui/material'
import { has } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_GOLF_HOLIDAY, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import Noimage from "../../assets/images/no-img.png";

const HolidayList = ({holidays}) => {

  const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
  const [currencyCode , setCurrencyCode] = useState('')
  const [currencyRateList , setCurrencyRateList] = useState([]);

  useEffect(() => {
    if(userCurrencyCode){
        setCurrencyCode(userCurrencyCode)
}
  }, [userCurrencyCode]);

  useEffect(() => {
    if(currencyRate){
      setCurrencyRateList(currencyRate)
}
  }, [currencyRate]);
  
  const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
    const currencyRate = currencyRateList[currency];
    const convertedPrice =  price / currencyRate
    return convertedPrice.toFixed(2);
}

  const functionPriceStartFrom = (priceArray) => {
    if (priceArray?.length > 0) {
      const prices = priceArray.map(item => item.up_golfer_single);
      const lowestPrice = Math.min(...prices);
      const currencyPerPrice = currencyRateList[priceArray[0]?.currency_id?.currencyCode];
      const convertedPrice =  lowestPrice / currencyPerPrice
      return convertedPrice.toFixed(2);
    }
    return 0.00;
  };


  
  
    const navigation = useNavigate();
    
    const dispatch = useDispatch()
    const { bookingData } = useSelector(state => state.booking)
    const SearchDetailsPage = (slug) => {   
        navigation('/SearchDetailsPage/'+slug)
    };
    const [priceStartFrom , setPriceStartFrom] = useState(0)
   
    const Ratings = (value) => {
        let stars = [];
            for (let i = 1; i <= value; i++) {
            stars.push(
            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
            viewBox="0 0 20 20" fill="none">
            <path
            d="M11.4421 2.92495L12.9087 5.85828C13.1087 6.26662 13.6421 6.65828 14.0921 6.73328L16.7504 7.17495C18.4504 7.45828 18.8504 8.69162 17.6254 9.90828L15.5587 11.975C15.2087 12.325 15.0171 13 15.1254 13.4833L15.7171 16.0416C16.1837 18.0666 15.1087 18.85 13.3171 17.7916L10.8254 16.3166C10.3754 16.05 9.63375 16.05 9.17541 16.3166L6.68375 17.7916C4.90041 18.85 3.81708 18.0583 4.28375 16.0416L4.87541 13.4833C4.98375 13 4.79208 12.325 4.44208 11.975L2.37541 9.90828C1.15875 8.69162 1.55041 7.45828 3.25041 7.17495L5.90875 6.73328C6.35041 6.65828 6.88375 6.26662 7.08375 5.85828L8.55041 2.92495C9.35041 1.33328 10.6504 1.33328 11.4421 2.92495Z"
            fill="#FFB800" stroke="#FFB800" stroke-width="1.5"
            stroke-linecap="round" strokeLinejoin="round" />
            </svg></p>
        );
    }
        return stars;
    }



    const selectCurrentHoliday = async () => {

        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })




        dispatch({
            type: SELECTED_GOLF_HOLIDAY,
            payload: holidays
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedGolfHoliday: holidays,
                id: 'current'
            }
        })

        navigation("/GolfHolidayDetailsPage")
    }

    const totalNights = holidays.golf_holidays.number_of_nights
    .map(Number) 
    .reduce((acc, nights) => acc + nights, 0);

    const totalRounds = holidays.golf_holidays.number_of_golf_rounds
    .map(Number) 
    .reduce((acc, rounds) => acc + rounds, 0); 



    const [isExpanded, setIsExpanded] = useState(false);

  

    return (
                        <Grid xs={5.7} className="li-item-box">
                          <div className="listitem-card goliholidaycard">
                            <div className="listitem-image-sec">
                              <div className="listitem-img">
                                <img 
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = Noimage; 
                                }}
                                 src={holidays?.image_galleries[0]?.image} alt="image" />
                              </div>
                              <div className="img-subtag">
                                {/* <button onClick={selectCurrentHoliday}>Featured</button> */}
                                {holidays?.golf_holidays?.price_type == 'customizable' && (<button>Customizable</button>)}
                              </div>
                            </div>
                            <div className="listitem-bottom-con">
                              <div className="listitem-details">
                                <h4>{holidays?.golf_holidays?.holiday_name}</h4>
                                <div className="listitem-duration">
                                  <p className="listitem-country-sec"><span><svg
                                    xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 24 24" fill="none">
                                    <path
                                      d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                      fill="#292D32" />
                                  </svg></span>
                                  {holidays?.golf_holidays?.country_id ? holidays.golf_holidays.country_id.map(country => country?.name).join(', ') : ''}
                                   </p>

                                  
                                  <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 24 24" fill="none">
                                    <path
                                      d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z"
                                      fill="#292D32" />
                                    <path
                                      d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                                      fill="#292D32" />
                                  </svg></span>{holidays?.golf_holidays?.city_id ? holidays.golf_holidays.city_id.map(city => city?.name).join(', ') : ''} {totalNights > 0 ? `${totalNights}N` : ""}{totalNights > 0 && totalRounds > 0 ? "/" : ""}{totalRounds > 0 ? `${totalRounds}R` : ""}</p>
                                </div>
                              </div>
                              <div className='golfholi-bot-sec'>
                                <p className={`text ${isExpanded ? 'expanded' : 'truncated'}`} onClick={selectCurrentHoliday}><div dangerouslySetInnerHTML={{ __html: holidays.golf_holidays.description }}/></p>
                                {/* <div>
      <p className={`text ${isExpanded ? 'expanded' : 'truncated'}`} 
         dangerouslySetInnerHTML={{ __html: holidays.golf_holidays.description }}>
      </p>
    </div> */}
    
                                <div className='golfholi-book-sec'>
                                  <p><span>From</span>{currencyCode} {functionPriceStartFrom(holidays?.holiday_price)}</p>
                                  <button onClick={selectCurrentHoliday}>Explore</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
    )
}

export default HolidayList